<template>

    <div v-if="v2" class="breadcrumb mobile breadcrumbv2">
        <span @click="handleBack()" class="arrowBack"><i class="fas fa-arrow-left"></i></span>
        <template v-for="(item, key) in path" :key="key">
            <router-link :class="{fontbold: key == 'residence', disabledLink: item.last}" :to="key !== 'appart' && key !== 'noRouter' ? getPathAppart(key) + item.id : ''">
                <span :class="{orangeneutral: item.last, fontbold: item.first}">
                    {{ item.name }}
                    <i v-if="!item.last" class="fas fa-chevron-right"></i>
                </span>
            </router-link>
        </template>
    </div>
    <div v-else class="breadcrumb mobile">
        <template v-for="(item, key) in path" :key="key">
            <router-link :to="key !== 'appart' && key !== 'noRouter' ? getPathAppart(key) + item.id : ''">
                <span>
                    {{ key !== "residence" ? "&nbsp;> " : "" }}{{ item.name }}
                </span>
            </router-link>
        </template>
    </div>

</template>
<script>
import v1mixin from "@/mixins/v1.js"

export default {
    name: "ResidenceBreadcrumb",
    mixins: [v1mixin],
    props: {
        path: {
            type: Object,
            default() {
                return {}
            },
        },
        v2: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleBack() {
            this.$router.go(-1);
        },
        //TODO les appels api doivent être ici. Envoyer les params en props
        getPathAppart(key) {
            if (key === "batiment") {
                return "/building/"
            } else if (key === "cage") {
                return "/stairwell/"
            } else if (key === "cylinder") {
                return  `/${this.$route.params.type}/${this.$route.params.id}/cylinder`
            } else {
                return "/residence/"
            }     
        },
    },
}
</script>
<style lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";
@import "@/assets/scss/variables/fontSize.scss";
@import "@/assets/scss/global.scss";
.arrowBack{
    margin-right: 1rem;
    &:hover{
        cursor: pointer;
    }
}

.fontbold{
    font-weight: 900;
    font-family: "Avenir Heavy";
}

.fontboldinitial{
    font-weight: initial;
}

.breadcrumbv2{
    .fa-chevron-right{
        color: $orange-neutral;
        font-size: 0.7rem;
        margin-left: 5px;
    }
    span{
        font-size: 1rem;
    }
    a {
        text-decoration:initial;
        font-family: inherit;
    }

    a:hover {
        color: initial;
    }
    .disabledLink{
        cursor: default;
        pointer-events: none;
    }
}

</style>
